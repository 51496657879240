import { createSlice } from "@reduxjs/toolkit";

export const beneficiarySlice = createSlice({
    name: "beneficiary",
    initialState: {
      beneficiaries: {},
      pending: false,
      error: false,
      errorMsg: "",
    },
  
    reducers: {
      handleBeneficiaryRequest: (state) => {
        state.pending = true;
      },
      createBeneficiarySuccess: (state) => {
        state.pending = false;
        state.error = false;
        state.errorMsg = "";
      },
      getBeneficiarySuccess: (state, action) => {
        state.pending = false;
        state.beneficiaries = action.payload;
        state.error = false;
        state.errorMsg = "";
      },
      handleBeneficiaryError: (state, action) => {
        state.error = true;
        state.pending = false;
        state.errorMsg = action.payload;
      },
    },
  });
  
  export const { handleBeneficiaryRequest, createBeneficiarySuccess,getBeneficiarySuccess, handleBeneficiaryError } =
  beneficiarySlice.actions;
  export default beneficiarySlice.reducer;