import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { registerService } from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../Backdrop";
import countriesList from "country-list-with-dial-code-and-flag";
import "./style.css";
import { getCountries } from "../../services/getterService";

export default function Register() {
  const history = useHistory();
  const [countries, setCountries] = useState([]);
  const [countriesArr, setCountriesArr] = useState([]);

  const [credentials, setCredentials] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    password: "",
    confirmpassword: "",
    mobileNumber: "",
    country: "",
    countryCode: "",
  });
  const [loading, setLoading] = useState(false);

  const { logged } = useSelector((state) => state.auth);

  // const fetchCountries = () => {
  //   return new Promise(async () => {
  //     const { data } = await getCountries();
  //     if (data.success) {
  //       setCountries(data.result);
  //     }
  //   });
  // };
  const fetchCountries = () => {
    if (countriesList) {
      const data = countriesList.getAll();
      setCountries(data);
    }
  };

  const objToArr = () => {
    const arr = Object.values(countries);
    setCountriesArr(arr);
  };

  useEffect(() => {
    fetchCountries();
    objToArr();
  }, [countries]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.countryCode) {
      toast("Country code is required");
    }
    credentials.mobile = credentials.countryCode + credentials.mobile;
    setLoading(true);
    const { data } = await registerService(credentials, history);
    if (data.success) {
      toast.success(data.message);
      setLoading(false);
      history.push("/");
    } else {
      if (data.hasOwnProperty("errors")) {
        setLoading(false);

        data.errors.forEach((err) => {
          toast.error(err.msg);
        });
      } else {
        setLoading(false);
        toast.error(data.message);
      }
    }
  };
  const handleInputChange = (e) => {
    setCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    console.log(credentials);
  };

  useEffect(() => {
    if (logged) {
      history.push("/dashboard");
    }
  }, [logged]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="login-area">
      <div className="container">
        <div className="login-box ptb--100">
          <form onSubmit={handleSubmit}>
            <div className="login-form-head">
              <div className="logo" style={{ marginBottom: "20px" }}>
                <a href="index.html">
                  <h3 style={{ color: "black" }}>
                    <img
                      src={require("../../assets/images/logo.png")}
                      alt=""
                      srcSet=""
                      width={90}
                    />
                  </h3>
                </a>
              </div>
              <h4>Sign up</h4>
              <p>Hello there, Sign up and Join Us</p>
            </div>
            <div className="login-form-body">
              <div className="form-gp">
                <input
                  type="text"
                  id="fn"
                  name="firstname"
                  placeholder="First Name"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-user"></i>
                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="text"
                  id="mn"
                  name="middlename"
                  placeholder="Middle Name"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-user"></i>
                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="text"
                  id="ln"
                  name="lastname"
                  placeholder="Last Name"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-user"></i>
                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-email"></i>
                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="password"
                  id="rp"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-lock"></i>
                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="password"
                  id="rcp"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-lock"></i>
                <div className="text-danger"></div>
              </div>

              <div className="form-gp">
                <div className="d-flex">
                  <select
                    style={{ width: "40%" }}
                    name="countryCode"
                    id=""
                    className="bg-transparent border-bottom border-top-0 border-left-0 border-right-0 text-secondary mr-3"
                    onChange={handleInputChange}
                  >
                    <option disabled selected hidden></option>
                    {countriesArr.length >= 1 &&
                      countriesArr.map((country, index) => (
                        <option key={index} value={country.dial_code}>
                          {`${country.flag} ${country.code} ${country.dial_code}`}
                        </option>
                      ))}
                  </select>
                  <input
                    type="text"
                    id="rm"
                    name="mobileNumber"
                    placeholder="Mobile Number"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <i className="ti-mobile"></i>
                </div>

                <div className="text-danger"></div>
              </div>

              <div className="submit-btn-area">
                <button id="form_submit" type="submit">
                  Submit <i className="ti-arrow-right"></i>
                </button>
              </div>
              <div className="form-footer text-center mt-5">
                <p className="text-muted">
                  Have an account?{" "}
                  <a href="">
                    <Link to="/">
                      <span>Login</span>
                    </Link>
                  </a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
