import React from "react";
import "./style.css";

export default function None() {
  return (
      <div className="error-area ptb--100 text-center">
        <div className="container-fluid">
          <div className="error-content">
            <h2>404</h2>
            <p>The page you requested was not found.</p>
            <a href="/dashboard">Back to Dashboard</a>
          </div>
        </div>
    </div>
  );
}
