import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { resetPSWService, verifyCodeService } from "../../services/authService";
import { logout } from "../../redux/Slices/Auth";
import { useHistory, useParams } from "react-router-dom";

import Loading from "../Backdrop";
import "./style.css";

const ResetPassword = () => {
  const { logged } = useSelector((state) => state.auth);
  const { code } = useParams();
  const [valid, setValid] = useState(false);
  const [form, setform] = useState({
    authorized: "",
    password: "",
    confirm_password: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleAccess = () => {
    if (logged) {
      return history.push("/dashboard");
    }
  };

  const alertErrors = (data) => {
    if (data.hasOwnProperty("errors")) {
      data.errors.forEach((err) => {
        toast.error(err.msg);
      });
    } else {
      toast.error(data.message);
    }
  };

  const handleInputChange = (e) => {
    setform((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleReset = () => {
    setform({
      password: "",
      confirm_password: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const { data } = await resetPSWService(form);
    console.log(data);
    if (data.success) {
      toast.success(data.message);
      history.push("/");
    } else {
      alertErrors(data);
    }
    setLoading(false);
  };

  const handleVerify = async (e) => {
    setLoading(true);
    const { data } = await verifyCodeService({ code });
    setLoading(false);
    setValid(data.success);
    if (data.success) {
      toast.success(data.message);
      setform((prev) => ({
        ...prev,
        ["authorized"]: data.result,
      }));
    } else {
      alertErrors(data);
    }
  };

  useEffect(() => {
    handleAccess();
    handleVerify();
  }, [logged, code, valid]);

  return loading ? (
    <Loading />
  ) : (
    <div className="col-md-6 mx-auto mt-5">
      <h3 className="header-title">Reset Password</h3>
      {valid ? (
        <div className="card card-line">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  id="cb-rpassword"
                  value={form.password}
                  placeholder="New Password"
                  name="password"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>

              <div className="form-group">
                <input
                  className="form-control"
                  type="password"
                  placeholder="Confirm Password"
                  id="cb-rcpassword"
                  value={form.confirm_password}
                  name="confirm_password"
                  onChange={(e) => handleInputChange(e)}
                />
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <button className="btn btn-app-100" type="submit">
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="card card-line">
          <div className="card-body">
            <h4>Confirmation Code Invalid</h4>
            <p className="mb-3">Request a new passport reset code</p>
            <button
              className="btn btn-app-100 mr-2"
              type="submit"
              onClick={() => history.push("/")}
            >
              Login
            </button>
             <button
              className="btn btn-app-100"
              type="submit"
              onClick={() => history.push("/forgot-password")}
            >
              Forgot Password
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
