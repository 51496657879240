import axios from "../config/axios-config";
import {
  loginError,
  loginRequest,
  loginSuccess,
  tokenSuccess,
  pendingState,
  errorState,
  registerError,
  registerRequest,
  registerSuccess,
  clearOnly,
} from "../redux/Slices/Auth";
import { toast } from "react-toastify";

export const loginService = (formdata, dispatch, history) => {
  dispatch(loginRequest());
  axios
    .post("api_only/customer_auth/login", formdata)
    .then(async (response) => {
      dispatch(tokenSuccess(response.data.token));
      await verifyService(response.data.token, dispatch, history, "/dashboard");
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(loginError(error));
    });
};

export const verifyService = async (auth, dispatch, history, path) => {
  dispatch(pendingState(true));
  if (!localStorage.getItem("token")) {
    window.location = "/";
  }
  await axios
    .get("/api_only/customer_auth/verify", {
      headers: { Authorization: `Bearer ${auth}` },
    })
    .then((response) => {
      console.log(response);
      dispatch(loginSuccess(response.data));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(loginError(error));
      // dispatch(clearOnly(error));
    });
};

export const registerService = async (formdata, history) => {
  return await axios
    .post("api_only/customer_auth/register", formdata)
    .catch((error) => error.response);
};

export const changePSWService = async (formdata) => {
  try {
    return await axios.post(
      "/api_only/customer_auth/update_password",
      formdata
    );
  } catch (error) {
    return error.response;
  }
};

export const resetPSWService = async (formdata) => {
  try {
    return await axios.post("/api_only/customer_auth/reset_password", formdata);
  } catch (error) {
    return error.response;
  }
};

export const confirmCodeService = async (formdata) => {
  return await axios
    .post("/api_only/customer_auth/confirm_code", formdata)
    .catch((error) => error.response);
};

export const verifyCodeService = async (formdata) => {
  try {
    return await axios.post(
      "/api_only/customer_auth/verify_resetcode",
      formdata
    );
  } catch (error) {
    return error.response;
  }
};

export const resetRequestService = async (formdata) => {
  return await axios
    .post("/api_only/customer_auth/forgot_password", formdata)
    .catch((error) => error.response);
};

export const logoutService = (dispatch) => {
  dispatch(pendingState(true));
  axios
    .get("/api_only/customer_auth/logout")
    .then((response) => {
      if (response.data.success) {
        localStorage.removeItem("userInfo");
        localStorage.removeItem("token");
        window.location.pathname = "/";
      }
      dispatch(pendingState(false));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
      dispatch(pendingState(false));
      dispatch(errorState(error));
      window.location.pathname = "/";
    });
};
