import React, { createRef } from "react";
import Layout from "./layout";
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import None from "./components/None/None";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import { verifyService } from "./services/authService";
import { store } from "./redux/store";
import { createBrowserHistory } from "history";
// import Policies from "./components/Policies/Policies";
import { useSelector } from "react-redux";
import Loading from "../src/components/Backdrop/index";

const token = localStorage.getItem("token");
let history = createBrowserHistory();

async function verifyToken() {
  await verifyService(token, store.dispatch, history, window.location.pathname);
}

if (token) verifyToken();

function App() {
  const { pending } = useSelector((state) => state.auth);

  if (pending) {
    return <Loading />;
  }

  return (
    <div>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/reset-password/:code" component={ResetPassword} />
        {/* <Route exact path="/policies/:policy" component={Policies} /> */}
        <Route path="/dashboard" render={(props) => <Layout />} />
        <Route path="*" exact={true} component={None} />
      </Switch>
    </div>
  );
}

export default App;
