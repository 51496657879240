import { createSlice } from "@reduxjs/toolkit";

export const exchangeRateSlice = createSlice({
  name: "exchangeRate",
  initialState: {
    exchangeRates: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleExchangeRateRequest: (state) => {
      state.pending = true;
    },
    getExchangeRateSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.exchangeRates = action.payload;
      state.errorMsg = "";
    },
    createExchangeRateSuccess: (state, action) => {
      state.pending = false;
      state.error = false;

      state.errorMsg = "";
    },
    handleExchangeRateError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateExchangeRateState: (state, action) => {
      state.exchangeRates.result = state.exchangeRates.result.filter(item => item.id !== action.payload.id);
    },
  },
});

export const {
  handleExchangeRateRequest,
  getExchangeRateSuccess,
  handleExchangeRateError,
  createExchangeRateSuccess,
  updateExchangeRateState
} = exchangeRateSlice.actions;
export default exchangeRateSlice.reducer;
