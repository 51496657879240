import { createSlice } from "@reduxjs/toolkit";

export const updateSlice = createSlice({
  name: "update",
  initialState: {
    beneficiary: Date.now(),
    transferID: "" ??Date.now(),
  },
  reducers: {
    updateState: (state, action) => {
      state.beneficiary = action.payload;
    },
    updateTransfer: (state, action) => {
     state.transferID = action.payload
    },
  },
});

export const { updateState, updateTransfer } = updateSlice.actions;
export default updateSlice.reducer;
