import { createSlice } from "@reduxjs/toolkit";

export const serviceFeeSlice = createSlice({
  name: "serviceFee",
  initialState: {
    serviceFees: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleServiceFeeRequest: (state) => {
      state.pending = true;
    },
    getServiceFeeSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.serviceFees = action.payload;
      state.errorMsg = "";
    },
    createServiceFeeSuccess: (state, action) => {
      state.pending = false;
      state.error = false;

      state.errorMsg = "";
    },
    handleServiceFeeError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateServiceFeeState: (state, action) => {
      state.serviceFees.result = state.serviceFees.result.filter(item => item.id !== action.payload.id);
    },
  },
});

export const {
  handleServiceFeeRequest,
  getServiceFeeSuccess,
  handleServiceFeeError,
  createServiceFeeSuccess,
  updateServiceFeeState
} = serviceFeeSlice.actions;
export default serviceFeeSlice.reducer;
