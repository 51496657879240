import React, { Suspense } from "react";
import Loading from "../components/Backdrop";
const OtherComponent = React.lazy(() => import("./Layout"));

function Admin() {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <OtherComponent />
      </Suspense>
    </div>
  );
}

export default Admin;
