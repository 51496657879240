import { createSlice } from "@reduxjs/toolkit";

const authToken = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

export const authSlice = createSlice({
  name: "user",
  initialState: {
    update: Date.now(),
    token: authToken,
    logged: "",
    auth: "",
    pending: "",
    error: "",
    errorMsg: "",
  },

  reducers: {
    loginRequest: (state) => {
      state.pending = true;
    },
    registerRequest: (state) => {
      state.pending = true;
    },
    tokenSuccess: (state, action) => {
      localStorage.setItem("token", action.payload);
      state.pending = false;
      state.token = action.payload;
      state.error = false;
      state.errorMsg = "";
    },

    loginSuccess: (state, action) => {
      const { user, success, session } = action.payload;

      state.pending = false;
      state.auth = user;
      state.logged = success;
      state.error = false;
      state.errorMsg = "";
      localStorage.setItem("session", session);
    },
    registerSuccess: (state, action) => {
      state.pending = false;
      // state.user = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    pendingState: (state, payload) => {
      state.pending = payload;
    },
    errorState: (state, action) => {
      state.errorMsg = action.payload;
    },

    loginError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      localStorage.clear();
      // window.location.href = "/";
    },

    registerError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateState: (state, action) => {
      state.update = Date.now();
    },
    logout: (state) => {
      state.error = false;
      state.auth = null;
      state.logged = false;
      localStorage.clear("token");
    },
    clearOnly: (state) => {
      state.auth = null;
      state.logged = false;
      localStorage.clear("token");
    },
  },
});

export const {
  loginRequest,
  registerRequest,
  loginSuccess,
  tokenSuccess,
  registerSuccess,
  pendingState,
  errorState,
  registerError,
  loginError,
  logout,
  updateState,
  clearOnly,
} = authSlice.actions;
export default authSlice.reducer;
