import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { loginService } from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { getCountries } from "../../services/getterService";
import countriesList from "country-list-with-dial-code-and-flag";

import Loading from "../Backdrop/index";

import "./style.css";

export default function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pending, logged } = useSelector((state) => state.auth);
  const [countries, setCountries] = useState([]);
  const [countriesArr, setCountriesArr] = useState([]);

  const [credentials, setCredentials] = useState({
    countryCode: "",
    mobile: "",
    password: "",
  });

  const fetchCountries = () => {
    if (countriesList) {
      const data = countriesList.getAll();
      setCountries(data);
    }
  };

  const objToArr = () => {
    const arr = Object.values(countries);
    setCountriesArr(arr);
  };

  useEffect(() => {
    fetchCountries();
    objToArr();
  }, [countries]);

  useEffect(() => {
    if (logged) {
      history.push("/dashboard");
    }
  }, [logged]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!credentials.countryCode) {
      toast("Country code is required");
    }
    if (!credentials.mobile) {
      toast("Mobile number is required");
    }
    if (!credentials.password) {
      toast("Password field cannot be empty");
    }

    credentials.mobile = credentials.countryCode + credentials.mobile;
    try {
      loginService(credentials, dispatch, history);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (e) => {
    setCredentials((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));

    console.log(credentials);
  };

  if (pending) {
    return <Loading />;
  }
  return (
    <div className="login-area">
      <div className="container">
        <div className="login-box ptb--100">
          <form onSubmit={handleSubmit}>
            <div className="login-form-head">
              <div className="logo" style={{ marginBottom: "20px" }}>
                <a href="index.html">
                  <h3 style={{ color: "black" }}>
                    <img
                      src={require("../../assets/images/logo.png")}
                      alt=""
                      srcSet=""
                      width={90}
                    />
                  </h3>
                </a>
              </div>
              <h4>Sign In</h4>
              <p>Hello there, Sign in and start managing your account</p>
            </div>
            <div className="login-form-body">
              {/* <div className="form-gp">
                <select
                  name="country"
                  id=""
                  placeholder="Select country"
                  className="w-100 py-2 bg-transparent border-bottom border-top-0 border-left-0 border-right-0 text-secondary"
                  onChange={handleInputChange}
                >
                  <option disabled selected hidden>
                    Select Country
                  </option>
                  {countries.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="text-danger"></div>
              </div> */}
              <div className="form-gp">
                <div className="d-flex">
                  <select
                    style={{ width: "35%" }}
                    name="countryCode"
                    id=""
                    placeholder="+1"
                    className="bg-transparent border-bottom border-top-0 border-left-0 border-right-0 text-secondary mr-3"
                    onChange={handleInputChange}
                  >
                    <option disabled selected hidden></option>
                    {countriesArr.length >= 1 &&
                      countriesArr.map((country, index) => (
                        <option key={index} value={country.dial_code}>
                          {/* {<span>{country.flag}</span>} */}
                          {`${country.flag} ${country.code} ${country.dial_code}`}
                        </option>
                      ))}
                  </select>
                  <input
                    type="text"
                    id="exampleInputEmail1"
                    name="mobile"
                    placeholder="Mobile Number"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <i className="ti-email"></i>
                </div>

                <div className="text-danger"></div>
              </div>
              <div className="form-gp">
                <input
                  type="password"
                  id="exampleInputPassword1"
                  name="password"
                  placeholder="Password"
                  onChange={(e) => handleInputChange(e)}
                />
                <i className="ti-lock"></i>
                <div className="text-danger"></div>
              </div>
              <div className="row mb-4 rmber-area">
                <div className="col-6">
                  {/* <div className="custom-control custom-checkbox mr-sm-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customControlAutosizing"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customControlAutosizing"
                    >
                      Remember Me
                    </label>
                  </div> */}
                </div>
                <div className="col-6 text-right">
                  <Link to="/forgot-password">
                    <span>Forgot Password?</span>
                  </Link>
                </div>
              </div>
              <div className="submit-btn-area">
                <button id="form_submit" type="submit">
                  Submit <i className="ti-arrow-right"></i>
                </button>
              </div>
              <div className="form-footer text-center mt-5">
                <p className="text-muted">
                  Don't have an account?{" "}
                  <Link to="/register">
                    <span>Register</span>
                  </Link>
                </p>
              </div>
              <div className="form-footer text-center mt-3">
                <p className="text-muted">
                  <span style={{ fontSize: "12px" }}>
                    immedi8 Financial Services
                  </span>
                </p>
                <p className="text-muted">
                  <span style={{ fontSize: "12px" }}>
                    2 Heigham Rd, London E6 2JG, UK
                  </span>
                </p>
                <p className="text-muted">
                  <span style={{ fontSize: "12px" }}>0584027</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
