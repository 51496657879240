//Modou
import { createSlice } from "@reduxjs/toolkit";



export const userPermissionsSlice = createSlice({

    name: "userPermissions",
    initialState: {value: 
    
        {
          user_id: 0,
          location : "user.location",
          permissions : []
        
        },
    
    },
    reducers: {
        addUserPermissions: (state, action) =>{
            state.value = action.payload;
        },
        
    }
});

export const { addUserPermissions } = userPermissionsSlice.actions;

export default userPermissionsSlice.reducer;