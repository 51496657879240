import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Slices/Auth";
import updateReducer from "./Slices/update";
import customerReducer from "./Slices/customerSlice";
import beneficiaryReducer from "./Slices/beneficiarySlice";
import exchangeRateReducer from "./Slices/exchangeRateSlice";
import locationReducer from "./Slices/locationSlice";
import serviceFeeReducer from "./Slices/serviceFeeSlice";
import transactionReducer from "./Slices/transactionSlice";


import userPermissionsReducer from './Slices/UserPermissions';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    customer: customerReducer,
    beneficiary: beneficiaryReducer,
    exchangeRate: exchangeRateReducer,
    location: locationReducer,
    serviceFee: serviceFeeReducer,
    transaction: transactionReducer,
    userPermissions: userPermissionsReducer,
    update: updateReducer
  },
});
