import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  confirmCodeService,
  resetRequestService,
} from "../../services/authService";
import { toast } from "react-toastify";
import "./style.css";

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [show, setShow] = useState(false);

  const handleCancel = () => {
    setEmail("");
    setCode("");
    setShow(false);
  };

  const handleRequest = async () => {
    const { data } = await resetRequestService({ email }, history);
    if (data.success) {
      toast.success(data.message);
      setShow(data.success);
    } else {
      if (data.hasOwnProperty("errors")) {
        data.errors.forEach((err) => {
          toast.error(err.msg);
        });
      } else {
        toast.error(data.message);
      }
    }
  };
  const handleConfirm = async () => {
    const { data } = await confirmCodeService({ email, code }, history);
    if (data.success) {
      history.push(`/reset-password/${data.result}`);
    } else {
      if (data.hasOwnProperty("errors")) {
        data.errors.forEach((err) => {
          toast.error(err.msg);
        });
      } else {
        toast.error(data.message);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return show ? handleConfirm() : handleRequest();
  };

  return (
    <div className="login-area">
      <div className="container">
        <div className="login-box ptb--100">
          <form onSubmit={handleSubmit}>
            <div className="login-form-head">
              <h4>{show ? "Confirm Reset Code" : "Forgot Password"}</h4>
              {show ? (
                <p>Enter confirmation code</p>
              ) : (
                <p>Hey! Forgot Password Your Password ? Reset Now</p>
              )}
            </div>
            <div className="login-form-body">
              {show ? (
                <div className="form-gp">
                  <input
                    type="text"
                    placeholder="Enter Confirmation"
                    id="exampleInputConfirmation"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <i className="ti-pencil-alt"></i>
                </div>
              ) : (
                <div className="form-gp">
                  <input
                    type="email"
                    placeholder="Enter e-mail"
                    id="exampleInputEmail1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <i className="ti-lock"></i>
                </div>
              )}
              <div className="row mb-4 rmber-area"></div>
              <div className="submit-btn-area mt-5">
                <button id="form_submit" type="submit">
                  {show ? "Confirm Now" : "Reset"}{" "}
                  <i className="ti-arrow-right"></i>
                </button>
              </div>
              <div className="form-footer text-center mt-5">
                {show ? (
                  <div className="cancel-btn-area">
                    <button
                      id="form_submit"
                      type="button"
                      onClick={() => handleCancel()}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <p className="text-muted">
                    Have an account?{" "}
                    <a href="">
                      <Link to="/">
                        <span>Login</span>
                      </Link>
                    </a>
                  </p>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
