import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    locations: [],
    singlelocation: [],
    pending: false,
    error: false,
    errorMsg: "",
  },

  reducers: {
    handleLocationRequest: (state) => {
      state.pending = true;
    },
    getAllLocationSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.locations = action.payload;
      state.errorMsg = "";
    },
    getSingleLocationSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.singlelocation = action.payload;
      state.errorMsg = "";
    },
    createLocationSuccess: (state, action) => {
      state.pending = false;
      state.error = false;
      state.errorMsg = "";
    },

    clearLocation: (state) => {
      state.locations = [];
    },

    handleLocationError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      state.locations = [];
    },
  },
});

export const {
  handleLocationError,
  handleLocationRequest,
  getAllLocationSuccess,
  createLocationSuccess,
  clearLocation,
  getSingleLocationSuccess,
} = locationSlice.actions;
export default locationSlice.reducer;
